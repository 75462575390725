byseidler.loader = (function($){
	"use strict";

	function init(){
		
	}

	return {
		init:init
	}

})(jQuery);